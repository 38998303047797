
























































































































































import Vue from 'vue';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { db } from '@/main';
import algoliasearch from 'algoliasearch';
import constants from '../constants/constants';
import { Request, RequestStatus } from '@/models/request.model';
import { Practice } from '@/models/practice.model';
import { Position } from '@/models/position.model';
import { User } from '@/models/user.model';
import router from '@/router';

export default Vue.extend({
  name: 'Requests',
  data() {
    return {
      isCandidate: this.$store.state.currentUser.isCandidate,
      selectedRequest: '',
      selectedRequests: [],
      search: null,
      snackbar: false,
      snackbarColor: '',
      snackbarText: '',
      showEditModalValue: false,
      showDetailsModalValue: false,
      footerProps: { 'items-per-page-options': [20, 50, 100, 200, 500, 1000] },
      headers: [
        {
          value: 'date',
          text: 'Date',
          sortable: true
        },
        {
          value: this.$store.state.currentUser.isCandidate
            ? 'practiceUid'
            : 'candidateUid',
          text: this.$store.state.currentUser.isCandidate
            ? 'Practice'
            : 'Candidate',
          sortable: false
        },
        { value: 'title', text: 'Title', sortable: false },
        { value: 'contractType', text: 'Contract', sortable: false },
        { value: 'internalReference', text: 'Reference', sortable: false }
      ],
      options: {} as any,
      page: 1,
      totalRows: 1,
      perPage: { value: 50, text: '50 per page' },
      request: {} as Request,
      requests: [] as Request[],
      client: algoliasearch(
        constants.ALGOLIA.APP_ID,
        constants.ALGOLIA.SEARCH_KEY
      ),
      topVisible: {} as firebase.firestore.QueryDocumentSnapshot<
        firebase.firestore.DocumentData
      >,
      bottomVisible: {} as firebase.firestore.QueryDocumentSnapshot<
        firebase.firestore.DocumentData
      >,
      searchResults: [],
      isBusy: false,
      isSearchLoading: false,
      tags: [],
      candidates: {},
      positions: {},
      practices: {},
      practiceDistances: {},
      practiceDurations: {},
      previousPageNumber: 1,
      selectedStatus: RequestStatus.open,
      statusses: [...Object.values(RequestStatus), 'All'],
      dataListener: (null as null | {}) as () => void
    };
  },
  methods: {
    updateShowDetailsModalValue(value: boolean) {
      this.showDetailsModalValue = value;
    },
    initData() {
      this.getTotalRequestCount();
      this.getData();
    },
    getTotalRequestCount() {
      const filter = `${
        this.$store.state.currentUser.isCandidate
          ? 'candidateUid'
          : 'practiceUid'
      }:${
        this.$store.state.currentUser.isCandidate
          ? this.$store.state.currentUser.uid
          : this.$store.state.currentUser.activePractice.uid
      }`;
      this.algoliaClient
        .search('', {
          filters: filter
        })
        .then(({ nbHits }: any) => {
          this.totalRows = nbHits > 0 ? nbHits : 1;
        })
        .catch((err: any) => {
          console.log(err);
          return null;
        });
    },
    getData() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      this.isBusy = true;
      let query = this.mainQuery;
      let order = 'desc' as 'asc' | 'desc';
      let sort = 'date';
      if (sortBy && sortBy.length > 0) {
        sort = sortBy[0];
      }
      if (sortDesc !== undefined && sortDesc.length > 0) {
        order = sortDesc[0] ? 'desc' : 'asc';
      }
      query = query.orderBy(sort, order);
      if ((this.selectedStatus as string) !== 'All') {
        query = query.where('status', '==', this.selectedStatus);
      }
      const pageDiff = (page ?? 1) - this.previousPageNumber;
      this.previousPageNumber = page ?? 1;
      switch (pageDiff) {
        case 0:
          query = query.limit(itemsPerPage ?? this.perPage.value);
          break;
        case 1:
          query = query
            .startAfter(this.bottomVisible)
            .limit(itemsPerPage ?? this.perPage.value);
          break;
        case -1:
          query = query
            .endBefore(this.topVisible)
            .limitToLast(itemsPerPage ?? this.perPage.value);
          break;
        default:
          query = query.limit(itemsPerPage ?? this.perPage.value);
      }
      if (this.dataListener) {
        this.dataListener();
      }
      this.dataListener = query.onSnapshot(
        (snapshot: firebase.firestore.QuerySnapshot) => {
          this.isBusy = false;
          if (snapshot.docs.length > 0) {
            this.requests = snapshot.docs.map(value => value.data() as Request);
            this.getPractices();
            this.getPositions();
            this.getCandidates();
            this.topVisible = snapshot.docs[0];
            this.bottomVisible = snapshot.docs[snapshot.docs.length - 1];
          } else {
            this.requests = [];
          }
        },
        (error: Error) => {
          console.error(error);
        }
      );
    },
    getCandidates() {
      this.candidates = {};
      const candidateUids = this.requests.map(
        (value: Request) => value.candidateUid
      );
      candidateUids.forEach((uid: string) => {
        db.collection('users')
          .doc(uid)
          .get()
          .then(
            async doc => {
              const user = doc.data() as User;
              Vue.set(this.candidates, user.uid, user);
            },
            (error: Error) => {
              console.error(error);
            }
          );
      });
    },
    getPositions() {
      this.positions = {};
      const practiceUids = this.requests.map(
        (value: Request) => value.positionUid
      );
      practiceUids.forEach((uid: string) => {
        db.collection('positions')
          .doc(uid)
          .get()
          .then(
            async doc => {
              const position = doc.data() as Position;
              Vue.set(this.positions, position.uid, position);
            },
            (error: Error) => {
              console.error(error);
            }
          );
      });
    },
    getPractices() {
      this.practiceDistances = {};
      this.practiceDurations = {};
      const practiceUids = this.requests.map(
        (value: Request) => value.practiceUid
      );
      practiceUids.forEach((uid: string) => {
        db.collection('practices')
          .doc(uid)
          .get()
          .then(
            async doc => {
              const practice = doc.data() as Practice;
              Vue.set(this.practices, practice.uid, practice);
            },
            (error: Error) => {
              console.error(error);
            }
          );
      });
    },
    selectedRow(request: Request) {
      router.push({
        name: 'RequestView',
        query: {
          requestUid: request.uid,
          candidateUid: request.candidateUid,
          positionUid: request.positionUid,
          practiceUid: request.practiceUid
        }
      });
    },
    setStatus(status: string) {
      this.$confirm(
        `Are you sure you want to update the status of the selected ${
          this.selectedRequests.length
        } ${this.selectedRequests.length > 1 ? 'items' : 'item'} to ${status}`
      ).then(res => {
        if (res) {
          const batch = db.batch();
          this.selectedRequests.forEach((request: Request) => {
            batch.set(
              db.collection('requests').doc(request.uid),
              { status: status },
              { merge: true }
            );
          });
          batch.commit().then(() => {
            this.selectedRequests = [];
            this.initData();
          });
        }
      });
    },
    showEditModal() {
      this.showEditModalValue = true;
    },
    showSnackbar(text: string, color: string) {
      this.snackbarText = text;
      this.snackbarColor = color;
      this.snackbar = true;
    }
  },
  created() {
    this.initData();
  },
  beforeDestroy() {
    if (this.dataListener) {
      this.dataListener();
    }
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true
    },
    selectedStatus() {
      this.initData();
    }
  },
  computed: {
    algoliaClient(): any {
      return this.client.initIndex(constants.ALGOLIA.INDEXES.REQUESTS);
    },
    mainQuery() {
      const queryRef = db
        .collection('requests')
        .where(
          `${
            this.$store.state.currentUser.isCandidate
              ? 'candidateUid'
              : 'practiceUid'
          }`,
          '==',
          `${
            this.$store.state.currentUser.isCandidate
              ? this.$store.state.currentUser.uid
              : this.$store.state.currentUser.activePractice.uid
          }`
        );
      return queryRef;
    }
  }
});
