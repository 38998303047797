var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('div',[_c('h1',{staticStyle:{"color":"#424242","display":"inline"}},[_vm._v(" Messages ")]),_c('v-select',{staticClass:"mt-5",attrs:{"items":_vm.statusses,"filled":"","dense":""},model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}}),(_vm.selectedRequests.length > 0)?_c('span',[_c('v-btn',{staticClass:"ml-3",staticStyle:{"display":"inline","float":"right"},attrs:{"color":"success","outlined":""},on:{"click":function($event){return _vm.setStatus('Appointed')}}},[_vm._v(" Appointed ")]),_c('v-btn',{staticClass:"ml-3",staticStyle:{"display":"inline","float":"right"},attrs:{"outlined":""},on:{"click":function($event){return _vm.setStatus('Open')}}},[_vm._v(" Re-open ")]),_c('v-btn',{staticStyle:{"display":"inline","float":"right"},attrs:{"color":"error","outlined":""},on:{"click":function($event){return _vm.setStatus('Closed')}}},[_vm._v(" Close ")])],1):_vm._e()],1)])],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"row-pointer",attrs:{"loading":_vm.isBusy,"loading-text":"Loading...","id":"requests-table","headers":_vm.headers,"items":_vm.requests,"footer-props":_vm.footerProps,"items-per-page":_vm.perPage.value,"page":_vm.page,"options":_vm.options,"server-items-length":_vm.totalRows,"fixed-header":"","height":"600px","show-select":"","item-key":"uid"},on:{"update:page":function($event){_vm.page=$event},"click:row":_vm.selectedRow,"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("timestampToDate")(item.date))+" ")]}},{key:"item.practice",fn:function(ref){
var item = ref.item;
return [(_vm.practices[item.practiceUid])?_c('span',[_vm._v(_vm._s(_vm.practices[item.practiceUid].name))]):_c('v-skeleton-loader',{attrs:{"width":"100","loading":true,"type":"text"}})]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [(_vm.positions[item.positionUid])?_c('span',[_vm._v(_vm._s(_vm.positions[item.positionUid].title))]):_c('v-skeleton-loader',{attrs:{"width":"100","loading":true,"type":"text"}})]}},{key:"item.contractType",fn:function(ref){
var item = ref.item;
return [(_vm.positions[item.positionUid])?_c('span',[_vm._v(_vm._s(_vm.positions[item.positionUid].contractType))]):_c('v-skeleton-loader',{attrs:{"width":"100","loading":true,"type":"text"}})]}},{key:"item.internalReference",fn:function(ref){
var item = ref.item;
return [(_vm.positions[item.positionUid])?_c('span',[_vm._v(_vm._s(_vm.positions[item.positionUid].internalReference))]):_c('v-skeleton-loader',{attrs:{"width":"100","loading":true,"type":"text"}})]}},(!_vm.isCandidate)?{key:"item.candidateUid",fn:function(ref){
var item = ref.item;
return [(_vm.candidates[item.candidateUid])?_c('span',[_vm._v(_vm._s(_vm.candidates[item.candidateUid].name)+" "+_vm._s(_vm.candidates[item.candidateUid].surname))]):_c('v-skeleton-loader',{attrs:{"width":"100","loading":true,"type":"text"}})]}}:null,(_vm.isCandidate)?{key:"item.practiceUid",fn:function(ref){
var item = ref.item;
return [(_vm.practices[item.practiceUid])?_c('span',[_vm._v(_vm._s(_vm.practices[item.practiceUid].name))]):_c('v-skeleton-loader',{attrs:{"width":"100","loading":true,"type":"text"}})]}}:null],null,true),model:{value:(_vm.selectedRequests),callback:function ($$v) {_vm.selectedRequests=$$v},expression:"selectedRequests"}})],1)],1),_c('v-snackbar',{attrs:{"top":"","right":"","timeout":"5000","color":_vm.snackbarColor},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }